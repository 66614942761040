<template>
  <main>
    <nuxt-layout>
      <u-dashboard-page>
        <u-dashboard-panel grow>
          <nuxt-page />
        </u-dashboard-panel>
      </u-dashboard-page>
    </nuxt-layout>
    <u-notifications />
    <u-modals />
    <u-slideovers />
  </main>
  <p style="display: none">© 2024 Form It, LLC. All rights reserved.</p>
</template>

<script lang="ts" setup>
import type { RealtimeChannel } from '@supabase/realtime-js'

useHead({
  htmlAttrs: {
    lang: 'en',
  },
  link: [
    {
      rel: 'manifest',
      href: '/site.webmanifest',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/apple-touch-icon.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: '/android-chrome-192x192.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicon-16x16.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicon-32x32.png',
    },
  ],
})

useSeoMeta({
  description: 'Powerful on demand form creator and data pipeline manager.',
  ogTitle: 'The Form App',
  ogDescription: 'Powerful on demand form creator and data pipeline manager.',
  ogImage: {
    type: 'image/png',
    url: 'https://theform.app/logo.png',
    width: 512,
    height: 512,
  },
  ogUrl: 'https://theform.app',
  twitterTitle: 'The Form App',
  twitterDescription:
    'Powerful on demand form creator and data pipeline manager.',
  twitterImage: {
    type: 'image/png',
    url: 'https://theform.app/logo.png',
    width: 512,
    height: 512,
  },
  twitterCard: 'summary',
})

const supabase = useSupabaseClient<AppDatabase>()
const channel = ref<RealtimeChannel | null>(null)
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
const timezoneCookie = useCookie('app-tz')

if (timezoneCookie.value !== timezone) {
  timezoneCookie.value = timezone
}

async function untrack() {
  try {
    if (channel.value) {
      await channel.value.untrack()
      await channel.value.unsubscribe()
      channel.value = null
    }
  } catch (error) {
    console.error('Error untracking realtime:', error)
  }
}

onMounted(async () => {
  untrack()

  channel.value = supabase
    .channel('postgres_changes')
    .on(
      'postgres_changes',
      {
        event: '*',
        schema: 'public',
      },
      (payload) => {
        window.dispatchEvent(
          new CustomEvent('realtime:payload', { detail: payload }),
        )
      },
    )
    .subscribe()
})

onBeforeUnmount(async () => {
  await untrack()
})
</script>
