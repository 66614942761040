import { default as datatoNJsFdEc4Meta } from "/vercel/path0/src/pages/[teamName]/form/[formId]/data.vue?macro=true";
import { default as index4P9eqFWsD4Meta } from "/vercel/path0/src/pages/[teamName]/form/[formId]/index.vue?macro=true";
import { default as integrations625v0IcWnbMeta } from "/vercel/path0/src/pages/[teamName]/form/[formId]/integrations.vue?macro=true";
import { default as manageLAaOiZzX26Meta } from "/vercel/path0/src/pages/[teamName]/form/[formId]/manage.vue?macro=true";
import { default as pipelineR2cpDDcw5lMeta } from "/vercel/path0/src/pages/[teamName]/form/[formId]/pipeline.vue?macro=true";
import { default as neweb4LL4TLWXMeta } from "/vercel/path0/src/pages/[teamName]/form/new.vue?macro=true";
import { default as index44QuNrmHLKMeta } from "/vercel/path0/src/pages/[teamName]/index.vue?macro=true";
import { default as manageosO5pFVNGwMeta } from "/vercel/path0/src/pages/[teamName]/manage.vue?macro=true";
import { default as privacy_45policyKQXKcFAhb9Meta } from "/vercel/path0/src/pages/docs/privacy-policy.vue?macro=true";
import { default as terms_45of_45serviceaLQh9rKCXvMeta } from "/vercel/path0/src/pages/docs/terms-of-service.vue?macro=true";
import { default as airtableRC21i2KiYIMeta } from "/vercel/path0/src/pages/e/airtable.vue?macro=true";
import { default as cancelledVY8XGwm28ZMeta } from "/vercel/path0/src/pages/e/cancelled.vue?macro=true";
import { default as confirm77tAgW5GhpMeta } from "/vercel/path0/src/pages/e/confirm.vue?macro=true";
import { default as successM02qfCUAVWMeta } from "/vercel/path0/src/pages/e/success.vue?macro=true";
import { default as indexkP7wmsbr11Meta } from "/vercel/path0/src/pages/index.vue?macro=true";
import { default as indexEZkodoHhCDMeta } from "/vercel/path0/src/pages/s/[formId]/index.vue?macro=true";
import { default as share_45the_45lovegCYE3ob7d5Meta } from "/vercel/path0/src/pages/s/[formId]/share-the-love.vue?macro=true";
import { default as sign_45ingsNb1nKdl2Meta } from "/vercel/path0/src/pages/sign-in.vue?macro=true";
import { default as sign_45upXYyXCgonEKMeta } from "/vercel/path0/src/pages/sign-up.vue?macro=true";
import { default as accountYPh8lyzQreMeta } from "/vercel/path0/src/pages/u/account.vue?macro=true";
import { default as recoveryGzPZNOccyxMeta } from "/vercel/path0/src/pages/u/recovery.vue?macro=true";
import { default as new6UASpBVPiQMeta } from "/vercel/path0/src/pages/u/team/new.vue?macro=true";
import { default as teams1kbSZzqSfJMeta } from "/vercel/path0/src/pages/u/teams.vue?macro=true";
export default [
  {
    name: "teamName-form-formId-data",
    path: "/:teamName()/form/:formId()/data",
    meta: datatoNJsFdEc4Meta || {},
    component: () => import("/vercel/path0/src/pages/[teamName]/form/[formId]/data.vue").then(m => m.default || m)
  },
  {
    name: "teamName-form-formId",
    path: "/:teamName()/form/:formId()",
    meta: index4P9eqFWsD4Meta || {},
    component: () => import("/vercel/path0/src/pages/[teamName]/form/[formId]/index.vue").then(m => m.default || m)
  },
  {
    name: "teamName-form-formId-integrations",
    path: "/:teamName()/form/:formId()/integrations",
    meta: integrations625v0IcWnbMeta || {},
    component: () => import("/vercel/path0/src/pages/[teamName]/form/[formId]/integrations.vue").then(m => m.default || m)
  },
  {
    name: "teamName-form-formId-manage",
    path: "/:teamName()/form/:formId()/manage",
    meta: manageLAaOiZzX26Meta || {},
    component: () => import("/vercel/path0/src/pages/[teamName]/form/[formId]/manage.vue").then(m => m.default || m)
  },
  {
    name: "teamName-form-formId-pipeline",
    path: "/:teamName()/form/:formId()/pipeline",
    meta: pipelineR2cpDDcw5lMeta || {},
    component: () => import("/vercel/path0/src/pages/[teamName]/form/[formId]/pipeline.vue").then(m => m.default || m)
  },
  {
    name: "teamName-form-new",
    path: "/:teamName()/form/new",
    meta: neweb4LL4TLWXMeta || {},
    component: () => import("/vercel/path0/src/pages/[teamName]/form/new.vue").then(m => m.default || m)
  },
  {
    name: "teamName",
    path: "/:teamName()",
    meta: index44QuNrmHLKMeta || {},
    component: () => import("/vercel/path0/src/pages/[teamName]/index.vue").then(m => m.default || m)
  },
  {
    name: "teamName-manage",
    path: "/:teamName()/manage",
    meta: manageosO5pFVNGwMeta || {},
    component: () => import("/vercel/path0/src/pages/[teamName]/manage.vue").then(m => m.default || m)
  },
  {
    name: "docs-privacy-policy",
    path: "/docs/privacy-policy",
    meta: privacy_45policyKQXKcFAhb9Meta || {},
    component: () => import("/vercel/path0/src/pages/docs/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "docs-terms-of-service",
    path: "/docs/terms-of-service",
    meta: terms_45of_45serviceaLQh9rKCXvMeta || {},
    component: () => import("/vercel/path0/src/pages/docs/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "e-airtable",
    path: "/e/airtable",
    meta: airtableRC21i2KiYIMeta || {},
    component: () => import("/vercel/path0/src/pages/e/airtable.vue").then(m => m.default || m)
  },
  {
    name: "e-cancelled",
    path: "/e/cancelled",
    meta: cancelledVY8XGwm28ZMeta || {},
    component: () => import("/vercel/path0/src/pages/e/cancelled.vue").then(m => m.default || m)
  },
  {
    name: "e-confirm",
    path: "/e/confirm",
    meta: confirm77tAgW5GhpMeta || {},
    component: () => import("/vercel/path0/src/pages/e/confirm.vue").then(m => m.default || m)
  },
  {
    name: "e-success",
    path: "/e/success",
    meta: successM02qfCUAVWMeta || {},
    component: () => import("/vercel/path0/src/pages/e/success.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexkP7wmsbr11Meta || {},
    component: () => import("/vercel/path0/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "s-formId",
    path: "/s/:formId()",
    meta: indexEZkodoHhCDMeta || {},
    component: () => import("/vercel/path0/src/pages/s/[formId]/index.vue").then(m => m.default || m)
  },
  {
    name: "s-formId-share-the-love",
    path: "/s/:formId()/share-the-love",
    meta: share_45the_45lovegCYE3ob7d5Meta || {},
    component: () => import("/vercel/path0/src/pages/s/[formId]/share-the-love.vue").then(m => m.default || m)
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: sign_45ingsNb1nKdl2Meta || {},
    component: () => import("/vercel/path0/src/pages/sign-in.vue").then(m => m.default || m)
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: sign_45upXYyXCgonEKMeta || {},
    component: () => import("/vercel/path0/src/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: "u-account",
    path: "/u/account",
    meta: accountYPh8lyzQreMeta || {},
    component: () => import("/vercel/path0/src/pages/u/account.vue").then(m => m.default || m)
  },
  {
    name: "u-recovery",
    path: "/u/recovery",
    meta: recoveryGzPZNOccyxMeta || {},
    component: () => import("/vercel/path0/src/pages/u/recovery.vue").then(m => m.default || m)
  },
  {
    name: "u-team-new",
    path: "/u/team/new",
    meta: new6UASpBVPiQMeta || {},
    component: () => import("/vercel/path0/src/pages/u/team/new.vue").then(m => m.default || m)
  },
  {
    name: "u-teams",
    path: "/u/teams",
    meta: teams1kbSZzqSfJMeta || {},
    component: () => import("/vercel/path0/src/pages/u/teams.vue").then(m => m.default || m)
  }
]