import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin({
  name: 'sentry',
  parallel: true,
  setup(nuxtApp) {
    const vueApp = nuxtApp.vueApp
    const router = useRouter()
    const user = useSupabaseUser()
    const config = useRuntimeConfig()

    if (!config.public.sentry.dsn) {
      console.warn('Sentry DSN not set, skipping Sentry initialization')
      return
    }

    // https://github.com/getsentry/sentry-javascript/issues/12490
    /* eslint-disable @typescript-eslint/no-explicit-any */
    ;(globalThis as any)._sentryEsmLoaderHookRegistered = true

    Sentry.init({
      app: vueApp,
      dsn: config.public.sentry.dsn,
      integrations: [
        Sentry.captureConsoleIntegration({ levels: ['error'] }),
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
          maskAllInputs: false,
        }),
        Sentry.feedbackIntegration({
          colorScheme: 'system',
        }),
      ],
      attachStacktrace: true,
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      profilesSampleRate: 1.0,
    })

    watch(
      user,
      (value) => {
        if (value) {
          Sentry.setUser({
            id: value.id,
            email: value.email,
            username: value.user_metadata.username,
            ip_address: '{{auto}}',
          })
        } else {
          Sentry.setUser(null)
        }
      },
      { immediate: true, deep: true },
    )
  },
})
