export default defineAppConfig({
  ui: {
    primary: 'indigo',
    gray: 'neutral',
    notifications: {
      position: 'top-auto bottom-0 right-auto left-[50%] translate-x-[-50%]',
    },
    notification: {
      transition: {
        enterFromClass:
          'translate-y-0 opacity-0 sm:translate-y-0 sm:translate-x-0',
        enterToClass: 'translate-y-0 opacity-100 sm:translate-x-0',
      },
    },
  },
})
