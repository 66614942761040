import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/vercel/path0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/vercel/path0/src/middleware/auth.ts"),
  form: () => import("/vercel/path0/src/middleware/form.ts"),
  "form-active": () => import("/vercel/path0/src/middleware/formActive.ts"),
  team: () => import("/vercel/path0/src/middleware/team.ts"),
  unauth: () => import("/vercel/path0/src/middleware/unauth.ts")
}